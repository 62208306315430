<template>
  <el-main>
    <el-form :model="formData" ref="form" :rules="rules" label-width="160px" class="formData">
      <el-form-item label="订单付款限时" prop="auto_cancel_time">
        <div class="contentBox">
          <el-input type="number" v-model="formData.auto_cancel_time"></el-input>
          <div class="tips">限时内未付款订单将自动取消(付款成功则加入组局),合伙人订单限时内未付款将自动取消组局(付款成功则可开始组局)</div>
        </div>
      </el-form-item>
      <el-form-item label="组局截至时间" prop="zuju_end_time">
        <div class="contentBox">
          <el-input type="number" v-model="formData.zuju_end_time"></el-input>
          <div class="tips">组局限时,预约到店时间前x分钟若组局未成功则组局失败,已加入组局用户自动退款</div>
        </div>
      </el-form-item>
      <el-form-item label="定金" prop="deposit">
        <div class="contentBox">
          <el-input type="number" v-model="formData.deposit"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="组局须知" prop="zuju_rule">
        <RichText width="600" height="400" editorId="notice" :richTxt="formData.zuju_rule" @soninfo="val => (formData.zuju_rule = val)"></RichText>
      </el-form-item>
      <el-form-item label="用户服务协议" prop="user_rule">
        <RichText width="600" height="400" editorId="agreement" :richTxt="formData.user_rule" @soninfo="val => (formData.user_rule = val)"></RichText>
      </el-form-item>
    </el-form>
    <!-- 保存按钮 -->
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
  components: {
    Preservation,
    RichText,
  },
  data() {
    return {
      formData: {
        auto_cancel_time: '',
        zuju_end_time: '',
        deposit: '',
        zuju_rule: '',
        user_rule: '',
      },
      rules: {
        auto_cancel_time: [{ required: true, message: '请设置订单付款限时', trigger: 'blur' }],
        zuju_end_time: [{ required: true, message: '请设置组局截至时间', trigger: 'blur' }],
        deposit: [{ required: true, message: '请设置定金', trigger: 'blur' }],
        zuju_rule: [{ required: true, message: '请输入组局须知', trigger: 'blur' }],
        user_rule: [{ required: true, message: '请输入用户协议', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取基础设置
    getData() {
      this.$axios.post(this.$api.yiyun.setInfo, this.formData).then(res => {
        if (res.code === 0) {
          for (const key in this.formData) {
            this.formData[key] = res.result[key];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 保存设置
    Preservation() {
      this.$refs.form.validate(valid => {
        this.$axios.post(this.$api.yiyun.setSet, this.formData).then(res => {
          if (res.code === 0) {
            this.$message.success('提交成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
  .formData {
    .contentBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .el-input {
      width: 400px;
      flex-shrink: 0;
      margin-right: 15px;
    }
    .towCol {
      width: 600px;
    }
    .tips {
      color: red;
    }
  }
}
</style>
